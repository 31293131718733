<template>

  <!--  获取所有充值记录-->
  <div style="padding: 20px">
    <el-form ref="form" :model="FindForm" label-width="80px" style="float: left">
      <el-form-item label="邮箱：">
        <el-input @blur="clear" style="width: 300px" placeholder="请输入用户的邮箱" v-model="FindForm.userName"></el-input>
        <el-button style="margin-left: 20px" @click="findSure">查询</el-button>
      </el-form-item>
      <div style="display: flex; justify-content: space-evenly">
        <div class="box-property">
          <img src="@/assets/img/个人资料.png" class="img-property">
          <span class="text-property">用户数量:</span>
          <br><br>
          <span class="text-data">{{ myInfo.AllUserCount }}</span>
        </div>
        <div class="box-property" style="margin-left: 20px">
          <img src="@/assets/img/注册邀请.png" class="img-property">
          <span class="text-property">今日注册人数:</span>
          <br><br>
          <span class="text-data">{{ myInfo.TodayRegCount }}</span>
        </div>
        <div class="box-property" style="margin-left: 20px">
          <img src="@/assets/img/账户总览.png" class="img-property">
          <span class="text-property">今日登录人数:</span>
          <br><br>
          <span class="text-data">{{ myInfo.TodayLoginCount }}</span>
        </div>
      </div>
    </el-form>

    <div style="margin-bottom: 10px" v-loading="login">
      <el-table :data="TableData" style="width: 100%" :row-style="{height: '60px'}" border>
        <el-table-column label="购买时间" prop="buy_time"/>
        <el-table-column label="商品ID" prop="storeDO.id"/>
        <el-table-column label="商品信息" width="550px" prop="storeDO.about"/>
        <el-table-column label="商品价格" prop="storeDO.price"/>
        <el-table-column label="购买用户ID" prop="user_id"/>
        <el-table-column label="购买用户" prop="username"/>
      </el-table>
    </div>

    <template>
      <el-pagination background layout="prev, pager, next" :current-page="pageIndex"
                     @current-change="handleCurrentChange" :total="1000"/>
    </template>
  </div>
</template>

<script>
import {GetAllRechargeHistory, getInfo} from '@/request/api.js'

export default {
  name: "GetAllRecharge",
  data() {
    return {
      login: true,
      TableData: [],
      tableData2: [],
      FindForm: {},
      pageIndex: 1,
      pageSize: 10,
      myInfo: {},
    }
  },
  methods: {
    findSure() {
      let username = this.FindForm.userName;
      this.tableData2 = this.TableData;
      let data = [];
      this.TableData.forEach(function (item, index) {
        if (item.username === username) {
          data.push(item);
        }
      })
      this.TableData = data;
    },
    clear() {
      if (this.FindForm.userName === '') {
        console.log(this.tableData2);
      }
    },
    handleCurrentChange(val) {
      this.login = true;
      GetAllRechargeHistory({
        pageIndex: val,
        pageSize: 5,
      }).then(res => {
        this.TableData = res.Data;
        let table = [];
        console.log(res.Data);
        res.Data.forEach(function (item, index) {
          if (item.storeDO !== null) {
            table.push(item);
          }
        })
        this.historyTable = table;
        this.login = false;
      })
    },
  },
  created() {
    getInfo().then(res => {
      this.myInfo = res.Data;
    })
    GetAllRechargeHistory({
      pageIndex: 1,
      pageSize: 5,
    }).then(res => {
      this.TableData = res.Data;
      let table = [];
      console.log(res.Data);
      res.Data.forEach(function (item, index) {
        if (item.storeDO !== null) {
          table.push(item);
        }
      })
      this.historyTable = table;
      this.login = false;
    })
  }
}
</script>

<style lang="less">
//小格子
.box-property {
  width: 280px;
  height: 70px;
  box-shadow: 0px 2px 2px #d0d0d0;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}
//图片属性
.img-property {
  width: 70px;
  float: left;
}
//文字属性
.text-property {
  font-size: 20px;
  margin-left: 30px;
}

//数据文字属性
.text-data {
  font-size: 20px;
  margin-left: 40px;
  color: dodgerblue;
}
</style>